import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';
import ReactWorldFlags from 'react-world-flags';
import stationsData from '../stationsListCountry.json';
import topStationsData from '../topStationsList.json';
import cityTranslationMap from '../dutchToNativeCityMap.json';

// Custom MenuList using react-window for efficient rendering
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;

  // Find the current selected option to set initial scroll position
  const [value] = getValue();
  const initialOffset = options.findIndex(option => option.value === value);

  // Render each row
  const renderRow = ({ index, style }) => (
    <div style={style} key={index} {...props.innerProps}>
      {children[index]}
    </div>
  );

  if (options.length > 0) {
    return (
      <List
        height={options.length > 0 ? Math.min(maxHeight, options.length * height) : 0} // Yes, dubplicate, but needed because of potential race conditions.
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset * height}
      >
        {renderRow}
      </List>
    );
  }
};

// Main CityPicker component
const CityPicker = ({ onSelect, placeholder = "Select a city" }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Helper function to bold matching characters
  const highlightMatch = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
    );
  };

  // Effect to handle search input and generate options
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const query = inputValue.trim().toLowerCase();

      if (query.length >= 2) {
        const uniqueStations = new Set();
        const results = [];

        // Helper to add stations uniquely by name
        const addUniqueStation = (station) => {
          const stationKey = station.name.toLowerCase();
          if (!uniqueStations.has(stationKey)) {
            uniqueStations.add(stationKey);
            results.push(station);
          }
        };

        // Helper to translate a station name
        const translateStationName = (cityPart, stationName) => {
          const cityName = cityPart.charAt(0).toUpperCase() + cityPart.slice(1);
          const rest = stationName.toLowerCase().split(' ').slice(1);
          const translatedStationName = [cityName, ...rest].join(' ');

          return translatedStationName;
        };

        // Helper function to determine the length of the common prefix between two strings
        const commonPrefixLength = (str1, str2) => {
          let i = 0;
          while (i < str1.length && i < str2.length && str1[i].toLowerCase() === str2[i].toLowerCase()) {
            i++;
          }
          return i;
        };

        // Helper to sort the results based on the starting characters of the query
        const sortResultsByQuery = (results, query) => {
          return results.sort((a, b) => {
            const aPrefixLength = commonPrefixLength(a.name, query);
            const bPrefixLength = commonPrefixLength(b.name, query);

            // Sort primarily by the length of the matching prefix
            if (aPrefixLength > bPrefixLength) {
              return -1; // a should come before b
            }
            if (aPrefixLength < bPrefixLength) {
              return 1; // b should come before a
            }

            // If both have the same prefix length, maintain their relative order
            return 0;
          });
        };

        // Combine top stations and all stations
        let allStations = [...topStationsData, ...stationsData];

        // Translate relevant station names and add them to allStations
        allStations.forEach((station) => {
          const stationNameLower = station.name.toLowerCase();

          // Extract city part from user input and translate it
          const inputCityPart = query.split(' ')[0];
          const inputTranslatedCityName = cityTranslationMap[inputCityPart.charAt(0).toUpperCase() + inputCityPart.slice(1)] || inputCityPart;

          // Check if the city part of the station name matches the translated input
          const stationCityName = stationNameLower.split(' ')[0];
          if (stationCityName === inputTranslatedCityName.toLowerCase()) {
            const translatedStationName = translateStationName(inputCityPart, station.name);
            allStations.push({ ...station, name: translatedStationName });
          }
        });

        // Identify and translate matching stations
        allStations.forEach((station) => {
          const stationNameLower = station.name.toLowerCase();

          if (stationNameLower.includes(query)) {
            addUniqueStation(station);
          }
        });

        // Sort results based on best match
        const resultsSorted = sortResultsByQuery(results, query);
        
        // Map results to options with React components
        const combinedOptions = resultsSorted.map(station => ({
          value: `${station.name}`,
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReactWorldFlags
                code={station.country.toUpperCase()}
                style={{ width: '24px', height: '18px', marginRight: '8px' }}
                alt={`${station.country} flag`}
              />
              <span>{highlightMatch(station.name, inputValue)}</span>
              {station.topStation && (
                <span
                  style={{
                    backgroundColor: 'var(--tomato)',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: '12px',
                    marginLeft: '8px',
                    fontSize: '12px'
                  }}
                >
                  Populair
                </span>
              )}
            </div>
          ),
        }));

        setOptions(combinedOptions);
      } else {
        setOptions([]);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  // Handle city change
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  return (
    <Select
      value={selectedCity}
      onChange={handleCityChange}
      onInputChange={setInputValue}
      options={options}
      placeholder={placeholder}
      className="citypicker"
      components={{ MenuList }}
      closeMenuOnSelect={true}
      noOptionsMessage={() =>
        inputValue.length < 2 ? "Start typing..." : "No matching stations"
      }
      menuIsOpen={inputValue.length >= 2}
    />
  );
};

export default CityPicker;
