import React, { useEffect, useState, useRef } from 'react';
import CityPicker from './CityPicker';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/nl";
import axios from 'axios';
import moment from 'moment';
import stationMappings from '../stations.json';
import nlStations from '../nl-stations.json';
import '../App.css';

registerLocale("nl", el);

function formatDate(inputDate) {
  // Check if the input is null, undefined, or in an incorrect format using a simple regex
  const datePattern = /^\d{2}-\d{2}-\d{4}$/;
  
  if (!inputDate || inputDate === "autoDDMMYYYY" || !datePattern.test(inputDate)) {
    if (inputDate !== "autoDDMMYYYY") {
      console.log(inputDate);
      console.warn('Invalid or null date. Setting default date.');
    } else {
      // Force dd-MM-yyyy pattern
      return moment().add(2, 'months').format('DD-MM-YYYY');
    }
    
    // Set the default date to two months from today
    const defaultDate = moment().add(2, 'months').format('MM-DD-YYYY');
    return defaultDate;
  }
  
  // Split the date into day, month, and year
  const [day, month, year] = inputDate.split('-');

  // Validate day, month, and year values
  if (parseInt(day, 10) > 31 || parseInt(day, 10) < 1 ||
      parseInt(month, 10) > 12 || parseInt(month, 10) < 1 ||
      year.length !== 4)
  {
    // Set the default date to two months from today
    const defaultDate = moment().add(2, 'months').format('MM-DD-YYYY');
    return defaultDate;
  }

  // Reformat the date to MM-DD-YYYY
  const reformattedDate = `${month}-${day}-${year}`;

  return reformattedDate;
}

const TripOptions = () => {
  const { origin, destination, date } = useParams();
  const navigate = useNavigate();
  const [tripOptions, setTripOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nsOrigin, setNsOrigin] = useState('');
  const [nsDestination, setNsDestination] = useState('');
  const [previousLastDepartureTime, setPreviousLastDepartureTime] = useState(null);
  const [requestCount, setRequestCount] = useState(0);
  const [uniqueTrips, setUniqueTrips] = useState(new Set());
  const [sortOption, setSortOption] = useState('best');
  const [loadingAvailability, setLoadingAvailability] = useState({});
  const [availableTrips, setAvailableTrips] = useState({});
  const [journeyWidthInPixels, setJourneyWidthInPixels] = useState(0);
  const [visibleVendorBoxes, setVisibleVendorBoxes] = useState({});
  const [showMoreOffers, setShowMoreOffers] = useState({});
  const [departureDateDDMMYYYY, setDepartureDateDDMMYYYY] = useState({}); // replaces raw 'date'
  const [departureDateMMDDYYYY, setDepartureDateMMDDYYYY] = useState({}); // replaces processed 'date'
  const [initialized, setInitialized] = useState(false);
  const journeyRef = useRef(null);
  const scrollRef = useRef(null);
  const vendorBoxRefs = useRef({});

  // Provide default values for local development. In production, this is passed by WordPress. 
  const tripPlannerData = window.tripPlannerData ?? {
    origin: "Amsterdam",
    destination: "Berlin",
    departureDate: formatDate('autoDDMMYYYY'),
    baseUrl: ''
  };

  useEffect(() => {
    if (!initialized) {
      const originalDepartureDate = date !== undefined ? date : formatDate('autoDDMMYYYY');
      setDepartureDateDDMMYYYY(originalDepartureDate);
      setDepartureDateMMDDYYYY(formatDate(originalDepartureDate));

      setInitialized(true);
    }
  }, [initialized]);

  const baseUrl = tripPlannerData.baseUrl;

  useEffect(() => {
    const fetchTripOptions = async (startDate) => {
      if (requestCount >= 10) {
        console.log('Reached maximum number of requests');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://traincitytrip.eu/trip-options`, {
          params: {
            origin,
            destination,
            date: startDate,
          },
        });
    
        if (response.data.length > 0) {
          const firstTrip = response.data[0]; // Get the first trip from the response
          const originStation = firstTrip.legs[0].origin;
          const destinationStation = firstTrip.legs[firstTrip.legs.length - 1].destination;

          setNsOrigin(getNSEncoding(originStation)); // Calculate and set nsOrigin
          setNsDestination(getNSEncoding(destinationStation)); // Calculate and set nsDestination
        
          const lastTrip = response.data[response.data.length - 1];
          const lastDepartureTime = new Date(lastTrip.departure);

          let inputDate; // Declare inputDate outside of the try-catch block

          try {
            inputDate = new Date(departureDateMMDDYYYY);
            if (isNaN(inputDate.getTime())) {  // Check if the date is invalid
                throw new Error('Invalid date value');
            }
          } catch (error) {
            console.error('Error occurred while creating Date object:', error);
            // Provide a fallback date or handle the error gracefully
            inputDate = new Date(); // Default to the current date as a fallback
          }

          if (lastDepartureTime.toDateString() !== inputDate.toDateString()) {
            console.log('Done fetching - different date');
            setLoading(false);
            return;
          }

          if (previousLastDepartureTime && lastDepartureTime <= new Date(previousLastDepartureTime)) {
            console.log('Done fetching - same or earlier departure');
            setLoading(false);
            return;
          }

          const newTrips = response.data.filter(trip => {
            const tripKey = `${trip.departure}-${trip.arrival}`;
            if (uniqueTrips.has(tripKey)) {
              return false;
            } else {
              uniqueTrips.add(tripKey);
              return true;
            }
          });

          setTripOptions(prevOptions => [...prevOptions, ...newTrips]);
          setPreviousLastDepartureTime(lastDepartureTime.toISOString());
          setRequestCount(requestCount + 1);
          setUniqueTrips(new Set(uniqueTrips));

          const nextStartDate = new Date(lastDepartureTime.getTime() + (2 * 60 * 60 * 1000));
          if (nextStartDate.toDateString() !== inputDate.toDateString()) {
            console.log('Stopping fetch due to next query being on a different date');
            setLoading(false);
            scrollToSearchRef();
          } else {
            if (nextStartDate) {
              fetchTripOptions(nextStartDate);
            } else {
              console.error(`Error processing next timestamp: ${nextStartDate}`);
              setLoading(false);
              scrollToSearchRef();
            }
          }
        } else {
          console.log('No more data received');
          setLoading(false);
          scrollToSearchRef();
        }
      } catch (error) {
        console.error('Error fetching trip options:', error);
        setLoading(false);
        scrollToSearchRef();
      }
    };

    const fetchInitialTrips = async () => {
      if (!departureDateMMDDYYYY || typeof departureDateMMDDYYYY === 'object') {
        return;
      }

      try {
        const initialDate = safeDate(convertToISOFormat(departureDateMMDDYYYY));
        await fetchTripOptions(initialDate);
      } catch (error) {
        console.error('Error in fetchInitialTrips:', error);
      }
    };

    fetchInitialTrips();
  }, [origin, destination, departureDateMMDDYYYY]);

  useEffect(() => {
    if (tripOptions.length > 0) {
      tripOptions.forEach((trip) => {
        checkTicketAvailability(trip);
      });
    }
  }, [tripOptions]);

  useEffect(() => {
    if (loading) return;
  
    const adjustLegDetails = () => {
      const measureWidth = () => {
        if (journeyRef.current) {
          setJourneyWidthInPixels(journeyRef.current.offsetWidth);
        }
      };

      measureWidth();
      const journeyLegs = document.querySelectorAll('.journey-leg');
  
      journeyLegs.forEach(journeyLeg => {
        const legDetails = journeyLeg.querySelector('.leg-details');
        const dashedLine = journeyLeg.querySelector('.dashed-line');
  
        if (legDetails && dashedLine) {
          const isWalking = legDetails.querySelector('.walking-icon');
          if (isWalking) return;
  
          legDetails.classList.remove('hidden', 'hide-text');
  
          const legDetailsWidth = legDetails.offsetWidth;
          const dashedLineWidth = dashedLine.offsetWidth;
  
          if (legDetailsWidth > 0.95 * dashedLineWidth) {
            legDetails.classList.add('hidden');
            legDetails.classList.remove('hide-text');
          } else if (legDetailsWidth > 0.75 * dashedLineWidth) {
            legDetails.classList.add('hide-text');
            legDetails.classList.remove('hidden');
          } else {
            legDetails.classList.remove('hidden', 'hide-text');
          }
        }
      });
    };
  
    adjustLegDetails();
    window.addEventListener('resize', adjustLegDetails);
  
    return () => {
      window.removeEventListener('resize', adjustLegDetails);
    };
  }, [loading, tripOptions]);

  const createCompositeKey = (departure, arrival) => {
    return `${departure}_${arrival}`;
  };

  const checkTicketAvailability = async (trip) => {
    const compositeKey = createCompositeKey(trip.departure, trip.arrival);
    setLoadingAvailability(prev => ({ ...prev, [compositeKey]: true }));

    try {
        const incompatibleOperators = ['operator1', 'operator2'];
        const dbOnlyOperators = ['FlixTrain', 'European Sleeper'];
        const vendors = [];

        // Determine vendor
        const priceIsKnown = !!trip.price; // Assuming `trip.price` is the way to check if a price is known
        const isTripInNL = nsOrigin.startsWith('NL') || nsDestination.startsWith('NL');

        if (
          isTripInNL && 
          !trip.legs.some(leg => incompatibleOperators.includes(leg.operator)) &&
          !trip.legs.some(leg => dbOnlyOperators.includes(leg.operator))
        ) {
          vendors.push('NS');
        }

        if (priceIsKnown) {
          vendors.push('DB');
        }

        if (trip.legs.length === 1 && trip.legs[0].operator === "European Sleeper") {
          vendors.push('European Sleeper');
        }

        const isAvailable = (vendors.length === 0);

        //setAvailableTrips(prev => ({ ...prev, [compositeKey]: isAvailable })); // TODO: check if needed

        // Generate booking URL based on the selected vendor
        const bookingUrls = [];

        if (vendors.includes('NS')) {
          const formattedDate = format(new Date(trip.departure), 'yyyyMMdd');
          const formattedDepartureTime = format(new Date(trip.departure), 'HHmm');
          const formattedArrivalTime = format(new Date(trip.arrival), 'HHmm');

          const directBookingUrl = `https://www.nsinternational.com/nl/treintickets-v3/#/search/${nsOrigin}/${nsDestination}/${formattedDate}/${formattedDepartureTime}/${formattedArrivalTime}?pax=A`;
          const ttBase = "https://www.nsinternational.com/traintracker/"
          const refCode = "943_35043_456543_";
          bookingUrls['NS'] = `${ttBase}?tt=${refCode}&r=${encodeURIComponent(directBookingUrl)}`;
        }
        
        if (vendors.includes('DB')) {
          const formattedDate = format(new Date(trip.departure), 'yyyy-MM-dd');
          const formattedTime = format(new Date(trip.departure), 'HH:mm:ss');
          const encodedOrigin = encodeURIComponent(trip.legs[0].origin);
          const encodedDestination = encodeURIComponent(trip.legs[trip.legs.length - 1].destination);

          const awId = "1693255";
          const dbDirectBookingUrl = `https%3A%2F%2Fint.bahn.de%2Fen%2Fbuchung%2Fstart%3Flang%3Dnl%26sts%3Dfalse%26so%3D${encodedOrigin}%26zo%3D${encodedDestination}%26hd%3D${formattedDate}T${formattedTime}%26dbkanal_003%3DL01_S01_D001_KAF0001_14964_Linkgenerator-individuelle-Verbindungen-INT-${awId}_LZ03`
          const awBase = `https://www.awin1.com/cread.php?awinmid=14964&awinaffid=${awId}&linkid=3451566`;
          bookingUrls['DB'] = `${awBase}&p=${encodeURIComponent(dbDirectBookingUrl)}`;
        }

        if (vendors.includes('European Sleeper')) {
          const directBookingUrl = `https://www.europeansleeper.eu/train/`;
          const refCode = "37347_2209752_456543_";
          bookingUrls['European Sleeper'] = `${directBookingUrl}?tt=${refCode}&r=`;
        }

        setAvailableTrips(prev => ({
            ...prev,
            [compositeKey]: {
                isAvailable,
                vendors,
                bookingUrls
            }
        }));
    } catch (error) {
        console.error('Error checking ticket availability:', error);
        setAvailableTrips(prev => ({ ...prev, [compositeKey]: { isAvailable: false, vendors: [], bookingUrls: [] } }));
    } finally {
        setLoadingAvailability(prev => ({ ...prev, [compositeKey]: false }));
    }
  };

  const scrollToSearchRef = () => {
    if (scrollRef.current) {
      const topPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  const resetState = () => {
    setLoading(true);
    setInitialized(false);
    setPreviousLastDepartureTime(null);
    setTripOptions([]);
    setRequestCount(0);
    setUniqueTrips(new Set());

    scrollToSearchRef();
  };

  const safeDate = (date) => {
    if (typeof date === 'object') {
      return null;
    }

    try {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            throw new Error('Invalid date value');
        }
        return parsedDate.toISOString();
    } catch (e) {
        console.error('Invalid date, applying fallback:', date, e);
        return null;
    }
  };

  // Define handlers to update state based on selected city
  const handleOriginChange = (selectedOption) => {
    if (selectedOption) {
      resetState();

      // Ensure all dynamic segments are encoded
      const slugifiedOrigin = slugify(selectedOption.value);
      const slugifiedDestination = slugify(destination);
      const slugifiedDate = slugify(departureDateDDMMYYYY);

      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDate}/`);
    }
  };

  const handleDestinationChange = (selectedOption) => {
    if (selectedOption) {
      resetState();

      // Ensure all dynamic segments are encoded
      const slugifiedOrigin = slugify(origin);
      const slugifiedDestination = slugify(selectedOption.value);
      const slugifiedDate = slugify(departureDateDDMMYYYY);

      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDate}/`);
    }
  };

  const handleDateDelta = (isReturn, daysDelta, fromNow = false) => {
    let baseDate = fromNow ? new Date() : new Date(departureDateMMDDYYYY);

    baseDate.setDate(baseDate.getDate() + daysDelta);
    
    if (!isReturn) {
      resetState();

      // Ensure all dynamic segments are encoded
      const slugifiedOrigin = slugify(origin);
      const slugifiedDestination = slugify(destination);
      const slugifiedDate = slugify(format(baseDate, 'dd-MM-yyyy'));

      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDate}/`);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      //setInitialized(false);
      //setDepartureDateDDMMYYYY(format(date, 'dd-MM-yyyy'));
      //setDepartureDateMMDDYYYY(format(date, 'MM-dd-yyyy'));
      
      resetState();

      // Ensure all dynamic segments are encoded
      const slugifiedOrigin = slugify(origin);
      const slugifiedDestination = slugify(destination);
      const slugifiedDate = slugify(format(date, 'dd-MM-yyyy'));

      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDate}/`);
    } else {
      console.error("Issue setting new date: {date}.");
    }
  };

  return (
    <div>
      <div className="tripplanner-header">
        <h2>Reisplanner (bèta)</h2>
        <div className="search-form">
          <div className="key-input is-active search-stations">
            <div className="station-row">
              <label className="input-label">Van</label>
              <CityPicker onSelect={handleOriginChange} placeholder={origin} />
            </div>
            <div className="station-row">
              <label className="input-label">Naar</label>
              <CityPicker onSelect={handleDestinationChange} placeholder={destination} />
            </div>
          </div>
          <div className="key-input is-active search-dates">
            <div className="date-rows-container">
              <div className="date-row">
                <div className="previousDayIn" onClick={() => handleDateDelta(false, -1)}>&lsaquo;</div>
                <div className="date-display">
                  <label className="input-label">Heen</label>
                  <DatePicker selected={(departureDateDDMMYYYY && new Date(safeDate(departureDateMMDDYYYY))) || null} onChange={handleDateChange} value={getFormattedDateShorthand(departureDateMMDDYYYY) + " 🖉"} locale="nl" /> 
                </div>
                <div className="nextDayIn" onClick={() => handleDateDelta(false, 1)}>&rsaquo;</div>
              </div>
              <div className="date-row">
                <div className="previousDayOut" onClick={() => handleDateDelta(true, -1)}>&lsaquo;</div>
                <div className="date-display">
                  <label className="input-label">Terug</label>
                  <span><span style={{fontSize: '10px'}}>Binnenkort ondersteund</span></span>
                </div>
                <div className="nextDayOut" onClick={() => handleDateDelta(true, 1)}>&rsaquo;</div>
              </div>
            </div>
            <div className="break"></div>
            <div className="moveDateAhead">
              <span className="tip">Tip &mdash; zoek goedkoop: </span>
              <span className="dateAheadButton" onClick={() => handleDateDelta(false, 60, true)}>nu + 2 maanden</span>
              <span className="dateAheadButton" onClick={() => handleDateDelta(false, 90, true)}>nu + 3 maanden</span>
            </div>
          </div>
          <div className="key-input is-active search-passengers">
            <br/>1 volwassene<br/>
            <span style={{fontSize: '10px'}}>Wijzigen binnenkort ondersteund</span>
          </div>
        </div>
      </div>
      <div ref={scrollRef}></div>
      <div className="sort-buttons">
        <button className={`sort-button ${sortOption === 'best' ? 'active' : ''}`} onClick={() => setSortOption('best')}>Beste</button>
        <button className={`sort-button ${sortOption === 'cheapest' ? 'active' : ''}`} onClick={() => setSortOption('cheapest')}>Prijs</button>
        <button className={`sort-button ${sortOption === 'fastest' ? 'active' : ''}`} onClick={() => setSortOption('fastest')}>Snelste</button>
        <button className={`sort-button ${sortOption === 'chronological' ? 'active' : ''}`} onClick={() => setSortOption('chronological')}>Eerder</button>
      </div>
      
      {loading && (
        <div className="flashlight-effect">We zijn enkele routes op het spoor. Nog even geduld...</div>
      )}

      {!loading && !tripOptions.length && (
        <div>Helaas konden we geen routes vinden op deze datum.</div>
      )}

      {(!loading && tripOptions.length > 0) && (
        <div className="trip-container">
          {tripOptions
            .slice() // Create a copy to avoid mutating the state directly
            .sort((a, b) => {
              const getDuration = (trip) => new Date(trip.arrival) - new Date(trip.departure);

              if (sortOption === 'best') {
                // Calculate effective duration with penalties for transfers based on legs
                const calculateEffectiveDuration = (trip) => {
                  const baseDuration = getDuration(trip);
                  const departureHour = new Date(trip.departure).getHours()
                  const arrivalHour = new Date(trip.arrival).getHours()
                  const nextDayArrival = () => {
                    return Math.ceil((new Date(trip.arrival) - new Date(trip.departure)) / (24 * 60 * 60 * 1000));
                  };

                  let penalty = 0;

                  const legs = Array.isArray(trip.legs) ? trip.legs : [];
                  
                  // Calculate transfer penalties
                  for (let i = 0; i < legs.length - 1; i++) {
                    const currentLeg = legs[i];
                    const nextLeg = legs[i + 1];
                    const transferDuration = new Date(nextLeg.departure) - new Date(currentLeg.arrival);
                    const transferHour = new Date(currentLeg.arrival).getHours()

                    if (transferDuration > 60 * 60 * 1000) { // over 1 hour
                      penalty += 0.2 * baseDuration + transferDuration;
                      if (transferHour >= 0 && transferHour < 7) {
                        penalty *= 4; // Long nightly transfer gives extra large penalty
                      }
                    } else {
                      penalty += 0.15 * baseDuration;
                      if (transferHour >= 0 && transferHour < 7) {
                        penalty *= 3; // Short nightly transfer gives large penalty
                      }
                    }

                    if (departureHour > 21 || departureHour < 6) {
                      penalty += 0.6 * baseDuration;
                    }

                    if (arrivalHour > 23 || arrivalHour < 7) {
                      penalty += 0.4 * baseDuration;
                    }

                    const daysArrivingNext = nextDayArrival();
                    if (daysArrivingNext > 0) {
                      penalty += 0.6 * daysArrivingNext * baseDuration;
                    }
                  }

                  return baseDuration + penalty;
                };

                const calculateScore = (trip) => {
                  const effectiveDuration = calculateEffectiveDuration(trip);
                  const price = trip.price?.amount || 350; // Default to 350 if price is unknown
                  const priceWeight = 0.1; // Reduce the weight of price
                
                  const weightedPrice = price * priceWeight;
                  const score = effectiveDuration + weightedPrice;
                
                  return score;
                };

                return calculateScore(a) - calculateScore(b);

              } else if (sortOption === 'fastest') {
                // Sort by fastest journey (pure duration)
                return getDuration(a) - getDuration(b);

              } else if (sortOption === 'cheapest') {
                // Sort by cheapest journey
                return (a.price?.amount || Infinity) - (b.price?.amount || Infinity);

              } else {
                // Sort chronologically by departure time
                return new Date(a.departure) - new Date(b.departure);
              }
            }).map((trip, index) => {
              // Lookup additional details from availableTrips using the trip ID
              const compositeKey = createCompositeKey(trip.departure, trip.arrival);
              const tripDetails = availableTrips[compositeKey] || {};
              const { vendors, bookingUrls } = tripDetails;
              
              const totalJourneyTime = calculateDurationPretty(trip.departure, trip.arrival);
              const numTransfers = trip.legs.length - 1;
              const longestTransfer = Math.max(...trip.legs.slice(1).map((leg, legIndex) => {
                // legIndex is already offset by slice, so use trip.legs[legIndex]
                const previousLeg = trip.legs[legIndex];
                return new Date(leg.departure) - new Date(previousLeg.arrival);
              }));

              const SHORT_DURATION_THRESHOLD = calculateDuration(trip.departure, trip.arrival) * 0.06 * (1 / (journeyWidthInPixels / 800));
              
              let tripHasHiddenStationNames = false;
              let previousShouldAddMarginStationName = false; // Track the previous leg's margin condition

              const isSleeperTrain = trip.legs.some(leg => leg.train_number.startsWith('NJ ')) || 
                trip.legs.some(leg => leg.train_number.startsWith('ES ') && leg.operator === "European Sleeper");

              return (
                <div className="trip-option" key={index}>
                  <div className="trip-time">{formatTime(trip.departure)}</div>
                  <div className="trip-journey" ref={journeyRef}>
                    {trip.legs.map((leg, legIndex) => {
                      const tripDuration = calculateDuration(trip.departure, trip.arrival);
                      const legDuration = calculateDuration(leg.departure, leg.arrival);
                      let legWidth = getWidth(legDuration, tripDuration);
                      const isWalking = leg.operator === "N/A";
                      const transferDuration = legIndex > 0 ? calculateTransferTime(trip.legs[legIndex - 1].arrival, leg.departure) : 0;
                      const transferWidthInPixels = (transferDuration / tripDuration) * journeyWidthInPixels;
                      const transferWidthPercentage = (transferDuration / tripDuration) * 100;
                      const transfer = calculateTransferTimePretty(trip.legs[legIndex - 1]?.arrival, leg.departure);

                      // Determine transfer shape and width
                      const minTransferWidth = 30; // Add some margin
                      const transferClass = transferWidthInPixels > minTransferWidth ? 'rounded-rectangle' : 'circle-shape';

                      // Determine style for the circle
                      const circleStyle = {
                        marginLeft: legIndex === 0 ? '-10px' : '0',
                        width: transferClass === 'rounded-rectangle' ? `${transferWidthPercentage}%` : '10px',
                      };

                      // Move leg label to the right when it is a long transfer
                      const moveLabelRight = {
                        marginLeft: transferClass === 'rounded-rectangle' ? `${transferWidthInPixels / 4 - 10}px` : '0',
                      };

                      // Update legWidth such that it includes the width of the transfer
                      legWidth += transferWidthPercentage;

                      // Calculate durations
                      const previousLegDuration = legIndex > 0 ? calculateDuration(trip.legs[legIndex - 1].departure, trip.legs[legIndex - 1].arrival) : Infinity;
                      const currentLegDuration = calculateDuration(leg.departure, leg.arrival);
                      const nextLegDuration = legIndex < trip.legs.length - 1 ? calculateDuration(trip.legs[legIndex + 1].departure, trip.legs[legIndex + 1].arrival) : Infinity;

                      // Determine if the margin should be added
                      const shouldAddMarginStationName = 
                        legIndex !== 0 && 
                        !previousShouldAddMarginStationName && (
                        previousLegDuration < SHORT_DURATION_THRESHOLD || 
                        currentLegDuration < SHORT_DURATION_THRESHOLD ||
                        nextLegDuration < (SHORT_DURATION_THRESHOLD / 2)
                      );
                      
                      // Determine if the station name should be hidden with tooltip on hover
                      const shouldHideStationName = shouldAddMarginStationName && nextLegDuration < SHORT_DURATION_THRESHOLD;

                      // Store the current leg's margin status for the next iteration
                      previousShouldAddMarginStationName = shouldAddMarginStationName;

                      // Prepare class name for station name
                      const stationNameClass = `station-name ${shouldHideStationName ? 'hidden' : ''} ${shouldAddMarginStationName ? 'extra-margin' : ''}`;

                      // Update the tripHasHiddenStationNames flag
                      if (shouldHideStationName) {
                        tripHasHiddenStationNames = true;
                      }

                      return (
                        <div className="journey-leg" key={legIndex} style={{ width: `${legWidth}%` }}>
                          <div className={`circle ${transferClass}`} style={circleStyle}>
                            <div className={stationNameClass} title={shorterStationName(leg.origin)}>
                              {shorterStationName(leg.origin)}
                            </div>
                            {!isWalking && legIndex > 0 && legIndex < trip.legs.length && transfer.formattedTime !== "0m" && (
                              <div className={`transfer-details ${transfer.colorClass}`}>
                                <div className="transfer-icon"></div>
                                <div className="transfer-time">
                                  {transfer.formattedTime}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={isWalking ? "solid-line" : "dashed-line"}></div>
                          <div className={`leg-details ${isWalking ? 'is-walking' : ''}`} style={moveLabelRight}>
                            {isWalking ? (
                              <div className="walking-icon">🚶</div>
                            ) : (
                              <>
                                {(leg.operator !== "Nederlandse Spoorwegen" || !(nlStations.includes(leg.origin) && nlStations.includes(leg.destination))) ? (
                                  <img src={`${baseUrl}/icons/${leg.operator.replace(/\s+/g, '').toLowerCase()}.png`} alt={leg.operator} className="operator-icon" />
                                ) : (
                                  <img src={`${baseUrl}/icons/ns-binnenlands.png`} alt="NS" className="operator-icon" />
                                )}
                              </>
                            )}
                            <div className="leg-duration">{calculateDurationPretty(leg.departure, leg.arrival)}</div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="circle">
                      <div className="station-name">{shorterStationName(trip.legs[trip.legs.length - 1].destination)}</div>
                    </div>
                  </div>
                  <div className="trip-time">
                    {formatTime(trip.arrival)}
                    {new Date(trip.arrival).getDate() !== new Date(trip.departure).getDate() && <span className="next-day">Volgende dag</span>}
                  </div>
                  <div className="vertical-dash"></div>
                  <div className="trip-price">
                    {!loadingAvailability[compositeKey] ? (
                      <>
                        {trip.price && availableTrips[compositeKey] ? (
                          <div className="trip-price-amount">
                            €{Math.floor(trip.price.amount).toLocaleString()}<sup>,{(trip.price.amount % 1).toFixed(2).substring(2)}</sup>
                          </div>
                        ) : (
                          <>
                          {!isSleeperTrain ? (
                            <span className="price-not-available">Prijs nog niet beschikbaar</span>
                          ) : (
                            <span className="price-differs">Prijs verschilt per accommodatietype</span>
                          )}
                          </>
                        )}

                        {vendors && vendors.length > 0 && (
                          <div className="trip-tickets">
                            <div className="ticket-label">
                              <span className="tickets">TICKETS</span>
                            </div>
                            <div className="vendor-list">
                              {vendors.map((vendor, idx) => {
                                let plusNMoreOffers = "";
                                const nMoreOffers = vendors.length - 1;
                                if (idx === 0 && nMoreOffers > 0) {
                                  plusNMoreOffers = `+ Nog ${nMoreOffers} aanbieder`;
                                  if (nMoreOffers > 1) {
                                    plusNMoreOffers += "s";
                                  }
                                }

                                let vendorLogo = "";
                                if (vendor === "NS") {
                                  vendorLogo = "nederlandsespoorwegen";
                                } else if (vendor === "DB") {
                                  vendorLogo = "dbfernverkehrag";
                                } else if (vendor === "European Sleeper") {
                                  vendorLogo = "europeansleeper";
                                }

                                const toggleOtherVendorsVisibility = () => {
                                  setVisibleVendorBoxes(prev => {
                                    const isVisible = !prev[compositeKey];
                                    const element = vendorBoxRefs.current[compositeKey];
                                    if (element) {
                                        if (isVisible) {
                                            element.style.height = `${element.scrollHeight}px`;
                                        } else {
                                            element.style.height = '0px';
                                        }
                                    }
                                    // Hide the 'more-offers' element after clicking
                                    setShowMoreOffers(prev => ({
                                      ...prev,
                                      [compositeKey]: false
                                    }));
                                    return {
                                        ...prev,
                                        [compositeKey]: isVisible
                                    };
                                  });
                                };
                                
                                return (
                                  <React.Fragment key={`fragment-${compositeKey}-${idx}`}>
                                    <div
                                        key={`offer-${compositeKey}-${idx}-${vendor}`}
                                        className={`vendor-box ${idx === 0 ? 'auto-expanded' : visibleVendorBoxes[compositeKey] ? 'expanded' : ''}`}
                                        ref={el => vendorBoxRefs.current[compositeKey] = el}
                                    >
                                      <a href={bookingUrls[vendor]} target="_blank" rel="sponsored nofollow">
                                        <img src={`${baseUrl}/icons/${vendorLogo}.png`} alt="Vendor Logo" className="vendor-logo" />
                                        <span className="book-text">Boeken</span>
                                        <div className="vendor-icons">
                                          <i className="fa fa-question-circle"></i>
                                          <i className="fa fa-credit-card"></i>
                                        </div>
                                      </a>
                                    </div>
                                    {showMoreOffers[compositeKey] !== false && (
                                      <div className="more-offers" onClick={() => toggleOtherVendorsVisibility(compositeKey)}>{plusNMoreOffers}</div>
                                    )}
                                  </React.Fragment>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </div>
                  <div className="trip-summary">
                    <div className="summary-item">
                      Reisduur: <strong>{totalJourneyTime}</strong>
                    </div>
                    <div className="summary-item">
                      Overstappen: <strong>{numTransfers}</strong>
                    </div>
                    {numTransfers > 0 && (
                      <div className="summary-item">
                        Langste overstap: <strong>{calculateDurationPretty(0, longestTransfer)}</strong>
                      </div>
                    )}
                    <div className="summary-item">
                     &nbsp;
                    </div>
                  </div>
                  <div className="trip-remarks">
                    {isSleeperTrain && (
                      <span>&#9432; Deze reis bevat een slaaptrein. Lees onze <a href="/hoe-werkt-een-nachttrein/" target="_blank">informatie over nachttreinen</a>.</span>
                    )}
                    {tripHasHiddenStationNames && (
                      <span>&#9432; We hebben enkele stationsnamen moeten verbergen in het overzicht vanwege de beschikbare ruimte.</span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const formatTime = (time) => {
  const date = new Date(time);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

// Helper function to safely format the date in a well-readable shorthand notation
function getFormattedDateShorthand(departureDate) {
  if (!departureDate || typeof departureDate === 'object') {
    return 'No date available';
  }

  try {
    const parsedDate = new Date(convertToISOFormat(departureDate));
    
    // Check if the parsedDate is a valid Date
    if (isNaN(parsedDate.getTime())) {
      throw new Error('Invalid date');
    }

    const currentYear = new Date().getFullYear();
    const departureYear = parsedDate.getFullYear();

    if (departureYear === currentYear) {
      return format(parsedDate, 'd') + ' ' + monthNameInDutch(parsedDate);
    } else {
      return format(parsedDate, 'dd-MM-yyyy');
    }
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid date';
  }
}

function convertToISOFormat(dateStr) {
  // Check if dateStr is a string
  if (typeof dateStr !== 'string') {
    console.error(`Input must be a string: ${dateStr}`);
    return;
  }

  // Split the date string into components
  const parts = dateStr.split('-');

  // Validate the format (MM-DD-YYYY)
  if (parts.length !== 3) {
    console.error('Invalid date format. Expected format is MM-DD-YYYY');
    return;
  }

  const [month, day, year] = parts;

  // Validate that month, day, and year are numbers and within valid ranges
  if (
    isNaN(month) || isNaN(day) || isNaN(year) || 
    month < 1 || month > 12 || 
    day < 1 || day > 31 || 
    year.length !== 4
  ) {
    console.error('Invalid date components. Ensure MM, DD, and YYYY are valid numbers');
    return;
  }

  // Pad month and day with leading zeros if necessary
  const paddedMonth = month.padStart(2, '0');
  const paddedDay = day.padStart(2, '0');

  // Return the date in ISO format (YYYY-MM-DD)
  return `${year}-${paddedMonth}-${paddedDay}`;
}

const calculateDurationPretty = (start, end) => {
  let startTime, endTime;

  // Check if start is a number (milliseconds since the epoch or duration)
  if (typeof start === 'number') {
    if (start > 1000000000000) { // Likely a timestamp in milliseconds
      startTime = new Date(start);
    } else {
      // Handle this as a time difference, assuming end is also a number
      startTime = 0;
      endTime = start;
    }
  } else {
    // Otherwise, assume start is a date string
    startTime = new Date(start);
  }

  // Check if end is a number (milliseconds since the epoch or duration)
  if (typeof end === 'number') {
    if (end > 1000000000000) { // Likely a timestamp in milliseconds
      endTime = new Date(end);
    } else if (typeof startTime === 'number') {
      // Handle this as a time difference if startTime was set to 0
      endTime = end;
    } else {
      // Handle mismatch (e.g., start as a date and end as a duration in ms)
      console.error('Mismatched types for start and end. Cannot calculate duration.');
      return 'Invalid inputs';
    }
  } else {
    // Otherwise, assume end is a date string
    endTime = new Date(end);
  }

  // Check if parsing resulted in valid dates
  if (isNaN(startTime) || isNaN(endTime)) {
    console.error('Invalid start or end time');
    return 'Invalid time';
  }

  // Calculate duration
  const duration = (endTime - startTime) / (1000 * 60); // duration in minutes
  const hours = Math.floor(duration / 60);
  const minutes = Math.round(duration % 60);
  
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

const calculateDuration = (start, end) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  return endTime - startTime;
};  

const getWidth = (duration, totalDuration) => {
  if (totalDuration <= 0) {
    console.error("Invalid total duration:", totalDuration);
    return 0;
  }
  return (duration / totalDuration) * 100;
};

const calculateTransferTime = (arrival, departure) => {
  return new Date(departure) - new Date(arrival);
};

const calculateTransferTimePretty = (arrival, departure) => {
  if (!arrival || !departure) { return }
  const arrivalTime = new Date(arrival);
  const departureTime = new Date(departure);
  const transferDuration = (departureTime - arrivalTime) / (1000 * 60); // duration in minutes
  const hours = Math.floor(transferDuration / 60);
  const minutes = transferDuration % 60;
  const formattedTime = `${hours ? hours + 'h ' : ''}${minutes}m`;

  let colorClass = '';
  if (transferDuration < 10) {
    colorClass = 'short-transfer';
  } else if (transferDuration > 45) {
    colorClass = 'long-transfer';
  }

  return { formattedTime, colorClass };
};

// Function to clean station names
const cleanStationName = (name) => {
  name = name.normalize('NFKD').replace(/[\u0300-\u036f]/g, ''); // Normalize and remove diacritics
  return name.replace(/[^\w\s-]/g, '').trim(); // Remove unwanted characters
};

const shorterStationName = (name) => {
  return name.replace(/\b(Centraal|Central|Centrum|Hbf|Station)\b/gi, '').replace(/\(.*?\)/g, '').trim();
};

// Function to calculate similarity between two strings
const similarity = (s1, s2) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
};

const editDistance = (s1, s2) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j;
      } else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue;
    }
  }
  return costs[s2.length];
};

// Function to get NS encoding from station name
const getNSEncoding = (stationName) => {
  // Attempt to resolve the station code as-is
  let station = stationMappings.find(st => st.name === stationName);

  // If no result, try using fuzzy matching on the cleaned and shortened name
  if (!station) {
    const cleanedName = cleanStationName(shorterStationName(stationName));
    station = stationMappings.find(st => similarity(cleanStationName(shorterStationName(st.name)), cleanedName) > 0.7);
  }

  return station ? station.code : stationName;
};

function slugify(text) {
  return text
    .normalize('NFD') // Normalize the string to decompose combined letters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and hyphens
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .toLowerCase(); // Convert to lowercase
}

const monthNameInDutch = (date) => {
  // Ensure the date is a valid Date object
  const validDate = new Date(date);
  if (isNaN(validDate)) {
    return;
  }

  // Create an Intl.DateTimeFormat object for Dutch locale
  const formatter = new Intl.DateTimeFormat('nl-NL', { month: 'long' });

  // Format the month
  const monthName = formatter.format(validDate);

  return monthName;
};

export default TripOptions;
